<template>
  <div class="picWallPanel">
    <ul class="statisticPanel">
      <li><span class="label">资源</span> <span class="count">547</span></li>
      <li><span class="label">地区</span> <span class="count">294</span></li>
    </ul>
    <br />
    <div class="operateBar">
      <el-button-group class="ml-4 displayMode">
        <el-button type="info" @click="
          () => {
            changeColumnNum(0);
          }
        ">
          <el-icon>
            <Files />
          </el-icon>
        </el-button>
        <el-button type="info" @click="
          () => {
            changeColumnNum(1);
          }
        ">
          <el-icon>
            <Menu />
          </el-icon>
        </el-button>
        <el-button type="info" @click="
          () => {
            changeColumnNum(2);
          }
        ">
          <el-icon>
            <Grid />
          </el-icon>
        </el-button>
      </el-button-group>
      <el-tag class="mx-1 tagPosition" type="dark" size="large" closable v-if="Store.state.selectedCity"
        @close="unselectCity">{{ Store.state.selectedCity }}</el-tag>
      <el-tag class="mx-1 tagPosition" type="dark" size="large" closable v-if="Store.state.selectedCategory"
        @close="unselectCategory">{{ Store.state.selectedCategory }}</el-tag>
    </div>
    <br />
    <div></div>
    <div class="picWall">
      <div class="wallContainer">
        <div class="item" :style="{ width: imgWidth, minWidth: imgWidth }"
          v-for="item in Store.getters.filteredPicWallImgList" :key="item.id" :data-id="item.id" @click="goToDetail">
          <img :src="item.img_url" @load="handleImageLoad" />
        </div>
      </div>
    </div>
    <a class="IPCRecord" href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2022015648号-2</a>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

// import testImg from "@/assets/sketch.webp";
import { Files, Menu, Grid } from "@element-plus/icons-vue";
const column = {
  nine: 9,
  four: 4,
};

const Store = useStore();
const router = useRouter();

// console.log('filtered imgs', Store.getters.filteredPicWallImgList)

const unselectCity = () => {
  Store.dispatch("unselectCity", "");
};

const unselectCategory = () => {
  Store.dispatch("unselectCategory", "");
};

const goToDetail = (e) => {
  const id = e.currentTarget.dataset.id;
  const app = window.app;
  app.needAnimating = false;
  router.push(`/detail/${id}`);
  // Store.commit("stopAnimation");
  e.stopPropagation();
};
//图片加载完成事件
const handleImageLoad = (e) => {
  const theImg = e.currentTarget;
  theImg.classList.add("loaded");
  e.stopPropagation();
};

const type = ref("nine");

const widthList = [];
const columnMode = [4, 6, 8];
columnMode.forEach((column) => {
  let width = 100 / column;
  widthList.push(width + "%");
});

// 默认默认一行6个 imgWidth [0] [1] [2] 对应 columnMode的 4， 6，  8
const imgWidth = ref(widthList[1]);
const changeColumnNum = (mode) => {
  imgWidth.value = widthList[mode];
};

</script>

<style lang="scss">
$theme-color: #15def8;
$dark-color: rgba(20, 26, 34, 0.9);

.picWallPanel {
  position: absolute;
  top: 100px;
  left: 30px;
  width: 50%;
  bottom: 0;
  overflow: hidden;
  z-index: 19;
}

.operateBar {
  .el-tag--dark {
    color: $dark-color;
    font-size: 14px;
    font-weight: bold;

    .el-tag__close {
      color: $dark-color;
      width: 30px;
      height: 30px;
    }
  }
}

.statisticPanel {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  background-color: $dark-color;
  width: 255px;

  li {
    flex: 1;
    color: white;
    padding: 5px;
    text-align: center;

    span.label {
      font-size: 16px;
    }

    span.count {
      font-size: 20px;
      color: $theme-color;
      margin-left: 10px;
      font-weight: bold;
    }
  }
}

.tagPosition {
  margin-left: 10px;
}

.picWall {
  position: absolute;
  bottom: 30px;
  top: 110px;
  width: 100%;
  //height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  box-sizing: border-box;
  background: transparent;

  /* padding-left: 20%; */
  /* padding-right: 10%; */
  .wallContainer {
    width: 100%;
    height: 100%;
    font-size: 0;

    /* margin-right: 8%; */
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-hidden-unscrollable;

    .item {
      box-sizing: border-box;
      padding: 15px;
      display: inline-block;
      transition: all 0.5s ease-out;

      img {
        opacity: 0;
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        transition: all 0.5s ease-out;
      }

      img.loaded {
        opacity: 1;
      }
    }
  }

  .wallContainer::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

.IPCRecord {
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  left: 0;
  color: #ccc;
  padding-left: 15px;
  line-height: 2em;
}
</style>
