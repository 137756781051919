<template>
  <el-dialog v-model="Store.state.cityStatisticDialogVisible" :title="Store.state.selectedCityOnGlobe" width="50%"
    center class="areaIntroDialog">
    <ul class="distributionPanel">
      <li class="typePanel">
        <div class="mylabel">
          <el-icon>
            <Picture />
          </el-icon>设计
        </div>
        <div class="mycounts">{{ Store.state.cityProjectStatisticInfo.design }}</div>
      </li>
      <li class="typePanel">
        <div class="mylabel">
          <el-icon>
            <Document />
          </el-icon>报告
        </div>
        <div class="mycounts">{{ Store.state.cityProjectStatisticInfo.report }}</div>
      </li>
      <li class="typePanel">
        <div class="mylabel">
          <el-icon>
            <WindPower />
          </el-icon>活动
        </div>
        <div class="mycounts">{{ Store.state.cityProjectStatisticInfo.activity }}</div>
      </li>
      <li class="typePanel">
        <div class="mylabel">
          <el-icon>
            <VideoCamera />
          </el-icon>视频
        </div>
        <div class="mycounts">{{ Store.state.cityProjectStatisticInfo.video }}</div>
      </li>
    </ul>
    <template #footer>
      <el-button class="dialogfooterbtn" type="primary" @click="learnMore">了解更多</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  Picture,
  Document,
  WindPower,
  VideoCamera,
} from "@element-plus/icons-vue";
import { useStore } from "vuex";

const Store = useStore();

const learnMore = () => {
  Store.commit("hideStatistics");
  // 根据城市 -> 获取该城市的项目列表，并展示在picwall上
  Store.dispatch("learnMore", Store.state.selectedCityOnGlobe);
  Store.commit('setPopoverVisibility', false);
};
</script>

<style></style>