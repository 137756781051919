<template>
  <!-- 地区的项目统计信息弹窗 -->
  <el-dialog v-model="Store.state.SystemIntroductionVisible" title="关于本项目" width="60%" center class="areaIntroDialog">
    <main class="introduction">
      <div class="article">
        <p>
          自2008年以来，湖南大学设计艺术学院师生针对“一带一路文化艺术”、“全球创意网络城市”、“中国乡村文化振兴”等主题，设置了一系列专业课程与工作坊，形成了调研资料500余项，涉及85个国家和地区，包含报告、设计、活动、视频等多种类别。本项目以地区为单位，对相关资料进行整理与汇总，向广大师生、研究人员分享。
        </p>
        <div class="logowall">
          <img src="@/assets/logo_wall.png" alt="" />
        </div>
      </div>
      <div class="footer"></div>
    </main>
  </el-dialog>
</template>

<script setup>
import { useStore } from "vuex";
const Store = useStore();
// const closeDialog = () => {
//   Store.commit("hideSystemIntroduction");
// };
</script>

<style lang="scss">
$theme-color: #15def8;
$dark-color: rgba(20, 26, 34, 0.9);

.areaIntroDialog {
  background: $dark-color;

  .el-dialog__headerbtn {
    .el-dialog__close {
      color: $theme-color;
      font-size: 40px;
    }
  }

  .el-dialog__header {
    padding: 60px 20px;

    .el-dialog__title {
      color: #fff;
      padding: 0;
      font-size: 48px;
      font-weight: bold;
      line-height: 1em;
    }
  }

  .el-dialog__body {
    color: #ccc;
    padding: 70px;
    padding-top: 0;
    font-size: 20px;
    line-height: 2em;
    letter-spacing: 0.2em;

    .logowall {
      height: 100px;

      img {
        width: 100%;
      }
    }
  }

  .el-dialog__footer {
    padding: 30px;

    .dialogfooterbtn {
      height: 60px;
      width: 150px;
      color: $dark-color;
      font-weight: bold;
    }
  }
}
</style>